import React from 'react'
import Title from 'components/title'
import LinkWrap from 'components/LinkWrap'
import { trFunction } from 'utils/functions'
import Layout from 'components/layout'
import { graphql } from 'gatsby'

const NotFoundPage = ({ data }) => {
  const locale = data.site.siteMetadata.locale
  const translations = data.translations
  const tr = (code) => trFunction(code, translations, locale)
  const page = data.thisPage
  return (
    <Layout>
      <div id="content">
        <Title title={page.title} />
        <section className="page-content wrap">
          <div className="wrap-text-large centered">
            {page.body && (
              <div
                dangerouslySetInnerHTML={{
                  __html: page.body.childMarkdownRemark.html,
                }}
              />
            )}
            <p>
              <LinkWrap to="/">{tr('RETURN_TO_HOME')}</LinkWrap>
            </p>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default NotFoundPage

export const notFoundPageQuery = graphql`
  query {
    site {
      siteMetadata {
        locale
      }
    }
    translations: allContentfulStringTranslations {
      edges {
        node {
          code
          en
          zh
        }
      }
    }
    thisPage: contentfulPages(slug: { eq: "404" }) {
      title
      slug
      body {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
